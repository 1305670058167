import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);
export default function createStore(state) {
  return new Vuex.Store({
    state() {
      return (
        state || {
          _modals: [],
          phone: "+7 967 555 8037",
          email: "mail@comrades.dev",
          address: "Махачкала, ул. Магомеда Ярагского, 23",
          inn: "0573017607",
          home_page: {
            services: [],
            principle_works: [],
            projects: [],
            competences: [],
            reviews: [],
            companyinfo: [],
            smis: {
              data: [],
            },
            heading: null,
            meta_title: null,
            meta_description: null,
            meta_keywords: null,
          },
          career_page: {
            companyinfo: {},
            vacancies: [],
          },
          about_page: {
            companyinfo: [],
            principle_works: [],
            clients: [],
            smis: [],
            meta: {
              meta_title: null,
              meta_description: null,
              meta_keywords: null,
              heading: null,
            }
          },
          projects_page: {
            projects: [],
            parent_categories: [],
            meta: {
              meta_title: null,
              meta_description: null,
              meta_keywords: null,
              heading: null,
            }
          },
          blog_page: {
            data: [],
            paginatorInfo: {
              total: 0,
            },
            meta: {
              meta_title: null,
              meta_description: null,
              meta_keywords: null,
              heading: null,
            }
          },
          smi_page: {
            data: [],
            paginatorInfo: {
              total: 0,
            },
            meta: {
              meta_title: null,
              meta_description: null,
              meta_keywords: null,
              heading: null,
            }
          },
          blog_item_page: {
            meta: {
              meta_title: null,
              meta_description: null,
              meta_keywords: null,
              heading: null,
            }
          },
          service_page: {
            principle_works: [],
            competences: [],
            clients: [],
            services: [],
            services_item: [],
            projects: [],
            meta: {
              meta_title: null,
              meta_description: null,
              meta_keywords: null,
              heading: null,
            }
          },
          services_item_page: {
            projects: [],
            service_item: {},
          },
          projects: [],
          services: {},
          project_page: {},
          clients: [],
          contacts_page: {
            meta: {
              meta_title: null,
              meta_description: null,
              meta_keywords: null,
              heading: null,
            }
          },
          presentation_page: {
            meta: {
              meta_title: null,
              meta_description: null,
              meta_keywords: null,
              heading: null,
            }
          }
        }
      );
    },
  });
}
